export const GET_QUESTIONS = "GET_QUESTIONS"
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS"
export const GET_QUESTIONS_FAIL = "GET_QUESTIONS_FAIL"

export const ADD_QUESTION = "ADD_QUESTION"
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS"
export const ADD_QUESTION_FAIL = "ADD_QUESTION_FAIL"

export const UPDATE_QUESTION = "UPDATE_QUESTION"
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS"
export const UPDATE_QUESTION_FAIL = "UPDATE_QUESTION_FAIL"

export const DELETE_QUESTION = "DELETE_QUESTION"
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS"
export const DELETE_QUESTION_FAIL = "DELETE_QUESTION_FAIL"
