export const GET_FREELANCER = "GET_FREELANCER"
export const GET_FREELANCER_SUCCESS = "GET_FREELANCER_SUCCESS"
export const GET_FREELANCER_FAIL = "GET_FREELANCER_FAIL"

export const ADD_FREELANCE = "ADD_FREELANCE"
export const ADD_FREELANCE_SUCCESS = "ADD_FREELANCE_SUCCESS"
export const ADD_FREELANCE_FAIL = "ADD_FREELANCE_FAIL"

export const UPDATE_FREELANCE = "UPDATE_FREELANCE"
export const UPDATE_FREELANCE_SUCCESS = "UPDATE_FREELANCE_SUCCESS"
export const UPDATE_FREELANCE_FAIL = "UPDATE_FREELANCE_FAIL"

export const DELETE_FREELANCE = "DELETE_FREELANCE"
export const DELETE_FREELANCE_SUCCESS = "DELETE_FREELANCE_SUCCESS"
export const DELETE_FREELANCE_FAIL = "DELETE_FREELANCE_FAIL"
