import React from "react"
// import { Link } from "react-router-dom"

const NotFound = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h3>NotFound</h3>
    </div>
  )
}

export default NotFound
