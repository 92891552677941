export const GET_RATES = "GET_RATES"
export const GET_RATES_SUCCESS = "GET_RATES_SUCCESS"
export const GET_RATES_FAIL = "GET_RATES_FAIL"

export const ADD_RATE = "ADD_RATE"
export const ADD_RATE_SUCCESS = "ADD_RATE_SUCCESS"
export const ADD_RATE_FAIL = "ADD_RATE_FAIL"

export const UPDATE_RATE = "UPDATE_RATE"
export const UPDATE_RATE_SUCCESS = "UPDATE_RATE_SUCCESS"
export const UPDATE_RATE_FAIL = "UPDATE_RATE_FAIL"

export const DELETE_RATE = "DELETE_RATE"
export const DELETE_RATE_SUCCESS = "DELETE_RATE_SUCCESS"
export const DELETE_RATE_FAIL = "DELETE_RATE_FAIL"
