export const GET_STUDENTS_G = "GET_STUDENTS_G"
export const GET_STUDENTS_G_SUCCESS = "GET_STUDENTS_G_SUCCESS"
export const GET_STUDENTS_G_FAIL = "GET_STUDENTS_G_FAIL"

export const GET_ATTENDENCE = "GET_ATTENDENCE"
export const GET_ATTENDENCE_SUCCESS = "GET_ATTENDENCE_SUCCESS"
export const GET_ATTENDENCE_FAIL = "GET_ATTENDENCE_FAIL"

export const ADD_ATTENDENCE = "ADD_ATTENDENCE"
export const ADD_ATTENDENCE_SUCCESS = "ADD_ATTENDENCE_SUCCESS"
export const ADD_ATTENDENCE_FAIL = "ADD_ATTENDENCE_FAIL"

