export const GET_STUDENTS = "GET_STUDENTS"
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS"
export const GET_STUDENTS_FAIL = "GET_STUDENTS_FAIL"

export const ADD_STUDENT = "ADD_STUDENT"
export const ADD_STUDENT_SUCCESS = "ADD_STUDENT_SUCCESS"
export const ADD_STUDENT_FAIL = "ADD_STUDENT_FAIL"

export const UPDATE_STUDENT = "UPDATE_STUDENT"
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS"
export const UPDATE_STUDENT_FAIL = "UPDATE_STUDENT_FAIL"

export const DELETE_STUDENT = "DELETE_STUDENT"
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS"
export const DELETE_STUDENT_FAIL = "DELETE_STUDENT_FAIL"
