/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"

/**
 * add user
 */
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"



// doctors
 export const ADD_NEW_DOCTOR = "ADD_NEW_DOCTOR"
 export const ADD_DOCTOR_SUCCESS = "ADD_DOCTOR_SUCCESS"
 export const ADD_DOCTOR_FAIL = "ADD_DOCTOR_FAIL"
 export const DELETE_DOCTOR = "DELETE_DOCTOR"
 export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS"
 export const DELETE_DOCTOR_FAIL = "DELETE_DOCTOR_FAIL"
 export const UPDATE_DOCTOR = "UPDATE_DOCTOR"
 export const UPDATE_DOCTOR_SUCCESS = "UPDATE_DOCTOR_SUCCESS"
 export const UPDATE_DOCTOR_FAIL = "UPDATE_DOCTOR_FAIL"
 
// clinics
 export const ADD_NEW_CLINICS = "ADD_NEW_CLINICS"
 export const ADD_CLINICS_SUCCESS = "ADD_CLINICS_SUCCESS"
 export const ADD_CLINICS_FAIL = "ADD_CLINICS_FAIL"
 export const DELETE_CLINICS = "DELETE_CLINICS"
 export const DELETE_CLINICS_SUCCESS = "DELETE_CLINICS_SUCCESS"
 export const DELETE_CLINICS_FAIL = "DELETE_CLINICS_FAIL"
 export const UPDATE_CLINIC = "UPDATE_CLINIC"
 export const UPDATE_CLINIC_SUCCESS = "UPDATE_CLINIC_SUCCESS"
 export const UPDATE_CLINIC_FAIL = "UPDATE_CLINIC_FAIL"
 
 // pages
 export const ADD_NEW_CONTACT = "ADD_NEW_CONTACT"
 export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS"
 export const ADD_CONTACT_FAIL = "ADD_CONTACT_FAIL"
 export const DELETE_CONTACT = "DELETE_CONTACT"
 export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS"
 export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL"
 export const UPDATE_CONTACT = "UPDATE_CONTACT"
 export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS"
 export const UPDATE_CONTACT_FAIL = "UPDATE_CONTACT_FAIL"
 
 
 // contact
 export const ADD_NEW_PAGE = "ADD_NEW_PAGE"
 export const ADD_PAGE_SUCCESS = "ADD_PAGE_SUCCESS"
 export const ADD_PAGE_FAIL = "ADD_PAGE_FAIL"
 export const DELETE_PAGE = "DELETE_PAGE"
 export const DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS"
 export const DELETE_PAGE_FAIL = "DELETE_PAGE_FAIL"
 export const UPDATE_PAGE = "UPDATE_PAGE"
 export const UPDATE_PAGE_SUCCESS = "UPDATE_PAGE_SUCCESS"
 export const UPDATE_PAGE_FAIL = "UPDATE_PAGE_FAIL"
 
 // consultation
 export const ADD_NEW_CONSULTATION = "ADD_NEW_CONSULTATION"
 export const ADD_CONSULTATION_SUCCESS = "ADD_CONSULTATION_SUCCESS"
 export const ADD_CONSULTATION_FAIL = "ADD_CONSULTATION_FAIL"
 export const DELETE_CONSULTATION = "DELETE_CONSULTATION"
 export const DELETE_CONSULTATION_SUCCESS = "DELETE_CONSULTATION_SUCCESS"
 export const DELETE_CONSULTATION_FAIL = "DELETE_CONSULTATION_FAIL"
 export const UPDATE_CONSULTATION = "UPDATE_CONSULTATION"
 export const UPDATE_CONSULTATION_SUCCESS = "UPDATE_CONSULTATION_SUCCESS"
 export const UPDATE_CONSULTATION_FAIL = "UPDATE_CONSULTATION_FAIL"
 
 // Apoit booking
 export const ADD_NEW_BOOKING = "ADD_NEW_BOOKING"
 export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS"
 export const ADD_BOOKING_FAIL = "ADD_BOOKING_FAIL"
 export const DELETE_BOOKING = "DELETE_BOOKING"
 export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS"
 export const DELETE_BOOKING_FAIL = "DELETE_BOOKING_FAIL"
 export const UPDATE_BOOKING = "UPDATE_BOOKING"
 export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS"
 export const UPDATE_BOOKING_FAIL = "UPDATE_BOOKING_FAIL"
 
 // open file
 export const ADD_NEW_FILE = "ADD_NEW_FILE"
 export const ADD_FILE_SUCCESS = "ADD_FILE_SUCCESS"
 export const ADD_FILE_FAIL = "ADD_FILE_FAIL"
 export const DELETE_FILE = "DELETE_FILE"
 export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS"
 export const DELETE_FILE_FAIL = "DELETE_FILE_FAIL"
 export const UPDATE_FILE = "UPDATE_FILE"
 export const UPDATE_FILE_SUCCESS = "UPDATE_FILE_SUCCESS"
 export const UPDATE_FILE_FAIL = "UPDATE_FILE_FAIL"
 
 // open file
 export const ADD_NEW_OFFER = "ADD_NEW_OFFER"
 export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS"
 export const ADD_OFFER_FAIL = "ADD_OFFER_FAIL"
 export const DELETE_OFFER = "DELETE_OFFER"
 export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS"
 export const DELETE_OFFER_FAIL = "DELETE_OFFER_FAIL"
 export const UPDATE_OFFER = "UPDATE_OFFER"
 export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS"
 export const UPDATE_OFFER_FAIL = "UPDATE_OFFER_FAIL"
 