export const GET_ACTIVITES = "GET_ACTIVITES"
export const GET_ACTIVITES_SUCCESS = "GET_ACTIVITES_SUCCESS"
export const GET_ACTIVITES_FAIL = "GET_ACTIVITES_FAIL"

export const ADD_ACTIVITY = "ADD_ACTIVITY"
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS"
export const ADD_ACTIVITY_FAIL = "ADD_ACTIVITY_FAIL"

export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY"
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS"
export const UPDATE_ACTIVITY_FAIL = "UPDATE_ACTIVITY_FAIL"

export const DELETE_ACTIVITY = "DELETE_ACTIVITY"
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS"
export const DELETE_ACTIVITY_FAIL = "DELETE_ACTIVITY_FAIL"
