export const GET_ACTIVITY_TYPE = "GET_ACTIVITY_TYPE"
export const GET_ACTIVITY_TYPE_SUCCESS = "GET_ACTIVITY_TYPE_SUCCESS"
export const GET_ACTIVITY_TYPE_FAIL = "GET_ACTIVITY_TYPE_FAIL"

export const ADD_ACTIVITY_TYPE = "ADD_ACTIVITY_TYPE"
export const ADD_ACTIVITY_TYPE_SUCCESS = "ADD_ACTIVITY_TYPE_SUCCESS"
export const ADD_ACTIVITY_TYPE_FAIL = "ADD_ACTIVITY_TYPE_FAIL"

export const UPDATE_ACTIVITY_TYPE = "UPDATE_ACTIVITY_TYPE"
export const UPDATE_ACTIVITY_TYPE_SUCCESS = "UPDATE_ACTIVITY_TYPE_SUCCESS"
export const UPDATE_ACTIVITY_TYPE_FAIL = "UPDATE_ACTIVITY_TYPE_FAIL"

export const DELETE_ACTIVITY_TYPE = "DELETE_ACTIVITY_TYPE"
export const DELETE_ACTIVITY_TYPE_SUCCESS = "DELETE_ACTIVITY_TYPE_SUCCESS"
export const DELETE_ACTIVITY_TYPE_FAIL = "DELETE_ACTIVITY_TYPE_FAIL"
