export const GET_COURSES = "GET_COURSES"
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS"
export const GET_COURSES_FAIL = "GET_COURSES_FAIL"

export const ADD_COURSE = "ADD_COURSE"
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS"
export const ADD_COURSE_FAIL = "ADD_COURSE_FAIL"

export const UPDATE_COURSE = "UPDATE_COURSE"
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS"
export const UPDATE_COURSE_FAIL = "UPDATE_COURSE_FAIL"

export const DELETE_COURSE = "DELETE_COURSE"
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS"
export const DELETE_COURSE_FAIL = "DELETE_COURSE_FAIL"
