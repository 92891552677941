export const GET_GROUPS = "GET_GROUPS"
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS"
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL"

export const ADD_GROUP = "ADD_GROUP"
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS"
export const ADD_GROUP_FAIL = "ADD_GROUP_FAIL"

export const UPDATE_GROUP = "UPDATE_GROUP"
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS"
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL"

export const DELETE_GROUP = "DELETE_GROUP"
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS"
export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL"

export const IMPORT_EXCEL = "IMPORT_EXCEL"
export const IMPORT_EXCEL_SUCCESS = "IMPORT_EXCEL_SUCCESS"
export const IMPORT_EXCEL_FAIL = "IMPORT_EXCEL_FAIL"
