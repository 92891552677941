
export const GET_PLATFORMS = "GET_PLATFORMS"
export const GET_PLATFORMS_SUCCESS = "GET_PLATFORMS_SUCCESS"
export const GET_PLATFORMS_FAIL = "GET_PLATFORMS_FAIL"

export const ADD_PLATFORM = "ADD_PLATFORM"
export const ADD_PLATFORM_SUCCESS = "ADD_PLATFORM_SUCCESS"
export const ADD_PLATFORM_FAIL = "ADD_PLATFORM_FAIL"

export const UPDATE_PLATFORM = "UPDATE_PLATFORM"
export const UPDATE_PLATFORM_SUCCESS = "UPDATE_PLATFORM_SUCCESS"
export const UPDATE_PLATFORM_FAIL = "UPDATE_PLATFORM_FAIL"

export const DELETE_PLATFORM = "DELETE_PLATFORM"
export const DELETE_PLATFORM_SUCCESS = "DELETE_PLATFORM_SUCCESS"
export const DELETE_PLATFORM_FAIL = "DELETE_PLATFORM_FAIL"
