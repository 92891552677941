export const GET_PARTNERS = "GET_PARTNERS"
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS"
export const GET_PARTNERS_FAIL = "GET_PARTNERS_FAIL"

export const ADD_PARTNER = "ADD_PARTNER"
export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS"
export const ADD_PARTNER_FAIL = "ADD_PARTNER_FAIL"

export const UPDATE_PARTNER = "UPDATE_PARTNER"
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS"
export const UPDATE_PARTNER_FAIL = "UPDATE_PARTNER_FAIL"

export const DELETE_PARTNER = "DELETE_PARTNER"
export const DELETE_PARTNER_SUCCESS = "DELETE_PARTNER_SUCCESS"
export const DELETE_PARTNER_FAIL = "DELETE_PARTNER_FAIL"
