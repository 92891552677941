export const GET_MENTORS = "GET_MENTORS"
export const GET_MENTORS_SUCCESS = "GET_MENTORS_SUCCESS"
export const GET_MENTORS_FAIL = "GET_MENTORS_FAIL"

export const ADD_MENTOR = "ADD_MENTOR"
export const ADD_MENTOR_SUCCESS = "ADD_MENTOR_SUCCESS"
export const ADD_MENTOR_FAIL = "ADD_MENTOR_FAIL"

export const UPDATE_MENTOR = "UPDATE_MENTOR"
export const UPDATE_MENTOR_SUCCESS = "UPDATE_MENTOR_SUCCESS"
export const UPDATE_MENTOR_FAIL = "UPDATE_MENTOR_FAIL"

export const DELETE_MENTOR = "DELETE_MENTOR"
export const DELETE_MENTOR_SUCCESS = "DELETE_MENTOR_SUCCESS"
export const DELETE_MENTOR_FAIL = "DELETE_MENTOR_FAIL"
